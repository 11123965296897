// Using IntersectionObserver
// Detect support for IntersectionObserver
if ('IntersectionObserver' in window) {
  const observerConfig = {
    root: null, // Viewport
    // rootMargin: '0px', // Does not work with viewport
    threshold: 0 // % visible - target intersection ratio
  }
  // Define observed targets
  const animatedElement = '.a-blockquote, .m-card, .m-figure-image'
  const targets = document.querySelectorAll(animatedElement)
  // Observer
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      // Deal with every intersection
      if (entry.isIntersecting) {
        entry.target.classList.add('js-animate')
      } else {
        entry.target.classList.remove('js-animate')
      }
    })
  }, observerConfig)
  // Observe targets
  targets.forEach(target => {
    observer.observe(target)
  })
} else {
  // If Intersection Observer API is not available
  const animateFallback = document.querySelectorAll(animatedElement)
  // For now we simply set the visibility to visible
  animateFallback.forEach(reveal => {
    reveal.setAttribute('style', 'visibility: visible')
  })
}
