// Scroll page to back-to-top
const classListBackToTop = document.getElementsByClassName('js-back-to-top')
const classLengthBackToTop = classListBackToTop.length

// If back-to-top button present
if (classLengthBackToTop !== 0) {
  const backToTop = document.querySelector('.js-back-to-top')
  const scrollAmount = 200

  // Show back-to-top button if scrollAmount is reached
  window.addEventListener('scroll', _ => {
    if (window.pageYOffset > scrollAmount) {
      backToTop.classList.add('m-back-to-top--visible')
    } else {
      backToTop.classList.remove('m-back-to-top--visible')
    }
  })

  // Scroll to top yay!
  backToTop.addEventListener('click', _ => {
    // https://github.com/cferdinandi/smooth-scroll
    var scroll = new SmoothScroll('a[href*="#"]', {
      // https://github.com/cferdinandi/smooth-scroll#scroll-speed
      speed: 200, // Integer. Amount of time in milliseconds it should take to scroll 1000px
      header: null, // Selector for fixed headers (must be a valid CSS selector)
      // https://codepen.io/cferdinandi/pen/jQMGaB
      easing: 'easeOutQuad'
    })
  })
}
