// Toggle header navigation
const toogleNavicon = document.querySelector('.js-navicon')

toogleNavicon.addEventListener('click', e => {
  e.preventDefault()
  document.querySelector('.o-header-navigation ul.navigation').classList.toggle('visible')
  document.querySelector('.o-header-navigation .js-navicon span').classList.toggle('active')
})

// Hide open navigation on orientation change and resize
// https://css-tricks.com/working-with-javascript-media-queries/#listen-for-changes
const mediaQuery = window.matchMedia('(min-width: 500px)') // Check if viewport matches screen MD size

function handleScreenMd(e) {
  if (e.matches) { // Check if the media query is true
    document.querySelector('.o-header-navigation ul.navigation').classList.remove('visible')
    document.querySelector('.o-header-navigation .js-navicon span').classList.remove('active')
  }
}

// Register event listener
mediaQuery.addListener(handleScreenMd)

// Initial check
handleScreenMd(mediaQuery)

// Highlight header navigation
function highlightHeaderNavigation() {
  var aHeader = document.querySelector('.o-header-navigation ul').getElementsByTagName('a')
  for (var i = 0; i < aHeader.length; i++) {
    if (aHeader[i].href.split('#')[0] == document.location.href.split('#')[0]) { // Compare a.href with location.href
      aHeader[i].parentElement.classList.add('current') // Add class to enclosing <li>
      aHeader[i].closest('ul').classList.add('parent') // Make subnavigation <ul> visible
    }
  }
}

// Highlight mainnavigation depending on subnavigation
setTimeout(() => { // Wait until subnavigation is visible
  const firstPath = document.location.href.split('/') // Get location parts
  // console.log('this location contains "' + firstPath[3] + '" in location href 3')
  if (firstPath[3] != 'index.html') { // Exlude home
    var parentUl = document.querySelector('.o-header-navigation ul li ul').classList.contains('parent')
    var aParent = document.querySelector('.parent')
    aParent.parentElement.classList.add('current')
  }
}, 1000)

// Highlight footer navigation
function highlightFooterrNavigation() {
  var aFooter = document.querySelector('.o-footer-navigation').getElementsByTagName('a')
  for (var i = 0; i < aFooter.length; i++) {
    if (aFooter[i].href.split('#')[0] == document.location.href.split('#')[0]) {
      aFooter[i].parentElement.classList.add('current') // Add class to enclosing <li>
    }
  }
}

// Apply highlight function
window.onload = function() {
  highlightHeaderNavigation(),
  highlightFooterrNavigation()
}
