// Configure slider highlight
var sliderHighlight = new Swiper('.o-slider-highlight', {
  // https://swiperjs.com/api/#parameters
  keyboard: true,
  roundLengths: true,
  // Navigation
  // https://swiperjs.com/api/#navigation
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  // Autoplay
  // https://swiperjs.com/swiper-api#param-autoplay
  autoplay: {
     delay: 5000,
     pauseOnMouseEnter: true
   },
  a11y: {
    enabled: true,
    prevSlideMessage: 'Vorherige Folie',
    nextSlideMessage: 'Nächste Folie'
  },
  // Pagination
  // https://swiperjs.com/api/#pagination
  pagination: {
    el: '.swiper-pagination',
  }
})
